<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Bidbonds</a></li>
      <li class="breadcrumb-item"><a href="#">Summary</a></li>
    </ol>
    <h1 class="page-header">Summary</h1>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="form-group col-md-3 col-md-push-3">
            <label class="fs">Start Date</label>
            <date-picker
                v-model="start.date"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="fs">End Date</label>
            <date-picker
                v-model="to.date"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
        </div>
        <div
            v-for="(item, i) in company_summary.data"
            :key="i"
            class="row row-space-10 m-b-10"
        >
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-teal m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Bid Bonds</div>
                <div class="stats-number">{{ $number.format(item.bidbond_count) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 70.1%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-blue m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Bidbond Exposure({{ item.currency }})</div>
                <div class="stats-number">{{ $number.format(item.exposure) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 40.5%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Commission(KES)</div>
                <div class="stats-number">{{ $number.format(item.commission) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Excise Tax(KES)</div>
                <div class="stats-number">{{ $number.format(item.excise_tax) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-space-10 m-b-10">
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-teal m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Total Bid Bonds</div>
                <div class="stats-number">{{ $number.format(total_bidbonds) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 70.1%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Total Commission(KES)</div>
                <div class="stats-number">{{ $number.format(total_commission) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Total Excise Tax(KES)</div>
                <div class="stats-number">{{ $number.format(total_excise_tax) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">Net Commission(KES)</div>
                <div class="stats-number">{{ $number.format(net_commission) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-space-10 m-b-10">
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-teal m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">MPF Share(KES)</div>
                <div class="stats-number">{{ $number.format(mpf_share) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 70.1%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="widget widget-stats bg-gradient-purple m-b-10">
              <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
              <div class="stats-content">
                <div class="stats-title">CoOperative Share(KES)</div>
                <div class="stats-number">{{ $number.format(ncba_share) }}</div>
                <div class="stats-progress progress">
                  <div class="progress-bar" style="width: 76.3%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment/src/moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      colors: ["red", "blue", "green", "yellow"],
      start: {
        date: moment().add('-1', 'week').toDate()
      },
      to: {
        date: moment().toDate()
      }
    }
  },
  computed: {
    company_summary() {
      return this.$store.getters.getCompanySummary;
    },
    period() {
      return [this.start.date, this.to.date].join();
    },
    total_bidbonds() {
      return this.company_summary.length === 0 ? 0 : this.company_summary.total_bidbond_count;
    },
    total_commission() {
      return this.company_summary.length === 0 ? 0 : this.company_summary.total_commission;
    },
    total_excise_tax() {
      return this.company_summary.length === 0 ? 0 : this.company_summary.total_excise_tax;
    },
    net_commission() {
      return this.company_summary.length === 0 ? 0 : this.company_summary.net_commission;
    },
    mpf_share() {
      return this.company_summary.length === 0 ? 0 : this.company_summary.mpf_share;
    },
    ncba_share() {
      return this.company_summary.length === 0 ? 0 : this.company_summary.ncba_share;
    }
  },
  watch: {
    period() {
      if (this.start.date && this.to.date) {
        this.fetch();
      }
    },
  },
  mounted() {
    this.getCompanySummary();
  },
  methods: {
    getCompanySummary() {
      if (!this.company_summary.length) {
        this.fetch();
      }
    },
    fetch() {
      this.$store.dispatch('fetchCompanySummary', {start: this.start.date, end: this.to.date});
    }
  }
}
</script>